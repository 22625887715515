import React, { Component,Fragment } from 'react';
import { Waypoint } from 'react-waypoint';
import $ from "jquery";
import img_blog01 from '../../asset/images/blog/img_blog01.jpg';
import img_blog02 from '../../asset/images/blog/img_blog02.jpg';
import img_blog03 from '../../asset/images/blog/img_blog03.jpg';
import img_blog04 from '../../asset/images/blog/img_blog04.jpg';
import img_blog05 from '../../asset/images/blog/img_blog05.jpg';
import img_blog06 from '../../asset/images/blog/img_blog06.jpg';
import img_blog07 from '../../asset/images/blog/img_blog07.jpg';
import img_blog08 from '../../asset/images/blog/img_blog08.jpg';

class Blog extends Component {

    lodeEvent = event => {
        event.preventDefault();
        $(".rowContain:hidden").slice(0, 3).slideDown();
        if ($(".rowContain:hidden").length === 0) {
            $("#loadMore").fadeOut('slow');
        }
    };
    componentDidMount(){
        $(".rowContain").slice(0, 3).show();
    }

    _handleWaypointEnter = ()=>{
        let blogItem = document.querySelector('.navAnimated li:nth-child(5)');
        blogItem.classList.add('active')

    }
    _handleWaypointLeave = ()=>{
        let blogItem = document.querySelector('.navAnimated li:nth-child(5)');
        blogItem.classList.remove('active')
    }

  render() {

    return (
      <Fragment>
        <Waypoint topOffset={'20%'} onEnter={this._handleWaypointEnter} onLeave={this._handleWaypointLeave}>
            <div className="ourBlog clearfix" id="blog">
                <div className="container">
                    <div className="titleSection wow fadeIn">
                        <h3>Our Blog</h3>
                        <p>Read our latest blog to know what is going on in the industry today.</p>
                    </div>

                    <div className="containSection clearfix">

                        <div className="rowItem clearfix">

                            <div className="rowContain" data-wow-delay="0.2s">
                                <a className="link" href="https://medium.com/oceanize-geeks/training-need-analysis-tna-7183c19ccb01" target="_blank" rel="noreferrer">
                                    <div className="image"><img src={img_blog06} alt="img_blog06" /></div>
                                    <div className="bottomContain">
                                        <h3>Training Need Analysis (TNA).</h3>
                                        <p>TNA means Training Need Analysis. Training Needs Analysis has another name and it is Learning Needs Analysis</p>
                                    </div>
                                </a>
                            </div>

                            <div className="rowContain" data-wow-delay="0.2s">
                                <a className="link" href="https://medium.com/oceanize-geeks/the-problems-of-strategic-human-resource-management-in-bangladesh-7426c7979ff5" target="_blank" rel="noreferrer">
                                    <div className="image"><img src={img_blog07} alt="img_blog07" /></div>
                                    <div className="bottomContain">
                                        <h3>The problems of strategic human resource</h3>
                                        <p>In Bangladesh strategic human resource management called SHRM. Lack of skilled human resource manager</p>
                                    </div>
                                </a>
                            </div>

                            <div className="rowContain" data-wow-delay="0.2s">
                                <a className="link" href="https://medium.com/oceanize-geeks/what-is-hrm-what-is-hrm-activities-eb1a7c4ddd9d" target="_blank" rel="noreferrer">
                                    <div className="image"><img src={img_blog08} alt="img_blog08" /></div>
                                    <div className="bottomContain">
                                        <h3>What is HRM? & What are HRM activities?</h3>
                                        <p>The full meaning of HRM is Human Resource Management. If i want to give the definition of Human Resource</p>
                                    </div>
                                </a>
                            </div>

                        </div>


                        <div className="loadMoreBtn">
                            <a href="#" id="loadMore" onClick={this.lodeEvent}>Load more</a>
                        </div>

                    </div>
                </div>
            </div>
        </Waypoint>
      </Fragment>
    )
  }
}
export default Blog;
