import React, { Component, Fragment } from 'react';
import { Link } from 'react-scroll';
import $ from "jquery";
import ScrollTop from '../TopBanner/ScrollTop';
import websiteLogo from '../../asset/images/BizNextIT-Logo.png';
// import websiteLogo from '../../asset/images/logo_BizNextITbd.svg';
// import scrollLogo from '../../asset/images/logo_right.png';
import scrollLogo from '../../asset/images/BizNextIT-Logo.png';
import centerImage from '../../asset/images/top-animation/img_top_bg.png';
import img_calendar from '../../asset/images/top-animation/img_calendar.png';
import img_code from '../../asset/images/top-animation/img_code.png';
import img_cloud2 from '../../asset/images/top-animation/img_cloud2.png';
import img_cloud1 from '../../asset/images/top-animation/img_cloud1.png';
import img_chart from '../../asset/images/top-animation/img_chart.png';
import img_loud from '../../asset/images/top-animation/img_loud.png';
import img_tree from '../../asset/images/top-animation/img_tree.png';
import img_men from '../../asset/images/top-animation/img_men.png';
import img_woman from '../../asset/images/top-animation/img_woman.png';

class TopBanner extends Component {

    constructor() {
        super();
        this.state = {
            isSticky: false
        }
    }
    onScroll = () => {
        if (window.scrollY > 100) {
            this.setState({ isSticky: true })
            $('#navsection').addClass('sticky');
        }
        else if (window.scrollY < 100) {
            this.setState({ isSticky: false })
            $('#navsection').removeClass('sticky');
        }
    }

    moveCursor = (event) => {
        let mouseMovement = document.getElementsByClassName('mouseMovement');
        mouseMovement[0].style.setProperty('transform', "translate3d(" + (event.clientX / 100) + "px, " + (event.clientY / 100) + "px, 0px)");
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        document.addEventListener("mousemove", this.moveCursor);

var slideShows = document.getElementsByClassName("slideShow");
var slideShows2 = document.getElementsByClassName("slideShow2");
var timers = [];

// Finds all elements by class name and adds an event listener to each.
function addListener(element, listenFor, functionToCall) {
  for (var i = 0; i < element.length; i++) {
    element[i].addEventListener(listenFor, functionToCall);
  }
}

function listen(element, listenFor, functionToCall) {
  element.addEventListener(listenFor, functionToCall);
}

//addDots();

function nextSlide(slideShowss,i,element=''){
  //i += 1;
  //console.log(slideShowss[i]);
	var currentSlideHere = slideShowss[i].getElementsByClassName("visible");
	
	var slides = slideShowss[i].getElementsByClassName("slide");
	var slidesArray = Array.prototype.slice.call(slides);
	var here = slidesArray.indexOf(currentSlideHere[0]);
	slides[here].classList.remove("visible");
	here += 1;
	if (here >= slides.length) {
		here = 0;
	}
	slides[here].classList.add("visible");
	
	if(element !=''){
	
	}
}

for(var i=0; i<slideShows.length; i++) {
	(function() {
    var count = i;
    timers.push(undefined);
    slideShow(slideShows,count,9000);
  }());
	
}
//function allSlide (){
for(var i=0; i<slideShows2.length; i++) {
	(function() {
  var count = i;
  timers.push(undefined);
    slideShow(slideShows2,count,3000,'.element');
  }());
	
}
//}
//allSlide();

function slideShow(slideShowss,count,interval,element='') {
	if(timers[count] == undefined ) {
		timers[count] = setInterval(function() {
		
		if(element !=''){
		  nextSlide(slideShowss,count,element);
		} else {
      nextSlide(slideShowss,count);
		}
		
		}, interval);
	} 
}
    }

  render() {
    const pthomebg = {
        paddingTop: '168px'  
    };
    $(document).ready(function() {


        
    });
    return (
      <Fragment>
        <ScrollTop/>
        <div className="topSection clearfix" id="home">
            <div className="topbg" id="homebg" style={pthomebg}>
                <div className="header" id="navsection">
                    <div className="headerContainer">
                        <div className="logo">
                            <h1>
                                <a href="/" id="logo">
                                    <img src={this.state.isSticky ? scrollLogo : websiteLogo }  alt="biznextit"/>
                                </a>
                            </h1>
                        </div>

                        <div className="mainMenu">
                            <ul className="navAnimated">
                                <li className='about'>
                                    <Link data-hover="About Us" to="about" spy={true} smooth={true} offset={-100} duration={500}>About Us</Link>
                                </li>
                                <li>
                                    <Link data-hover="Services" to="service" spy={true} smooth={true} offset={-100} duration={500}>Services</Link>
                                </li>
                                <li>
                                    <Link data-hover="Strength" to="strength" spy={true} smooth={true} offset={-100} duration={500}>Strength</Link>
                                </li>
                                <li>
                                    <Link data-hover="Team" to="team" spy={true} smooth={true} offset={-100} duration={500}>Team</Link>
                                </li>
                                <li>
                                    <Link data-hover="Blog" to="blog" spy={true} smooth={true} offset={-100} duration={500}>Blog</Link>
                                </li>
                                <li className="lang-menu">
                                    <div className="languages-switch">
                                        <a href="javascript:void(0);" className="" data-postion="1" data-lang="bn">BN</a>
                                        <a href="javascript:void(0);" className="actived" data-postion="2" data-lang="en">EN</a>
                                        <a href="javascript:void(0);" className="" data-postion="3"  data-lang="jp">JP</a>
                                    </div>
                                </li>
                                <li className="btnGetInTouch">
                                    <Link to="contact-us" spy={true} smooth={true} offset={-100} duration={500}>Get in touch</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottombg">
                    <div className="container">
                        <div className="aboutSection topContain">
                            <div className="topContainer clearfix">

                            <div className="pioneer">
                                <div className="slideShow pioneerText toutiao " id="1">
                                    <div className="slideDiv slide visible">
                                        <p>Committed to providing top-notch solutions</p>
                                    </div>
                                    <div className="slideDiv slide">
                                        <p>BizNextIT is the pioneer of future software</p>
                                    </div>
                                    <div className="slideDiv slide">
                                        <p>BizNextIT will help building your brands</p>
                                    </div>
                                </div>
                                <div className="slideShow2" id="2" style={{"display":"none"}}>

                                </div>

                                <div className="slideShow2" id="3">
                                    <div className="slideDiv slide visible technology">

                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>for Web</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">We build dynamic partnerships by providing robust and sustainable solutions. Our mission is to ensure customer happiness through a human-centered approach, future-proof technology, and best practices.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>for Smartphone</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp"> As an expert, we understand thoroughly what our customers need. So, we make your software with maximum excellence, creativity, and innovation.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>for Cloud</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp"> As an expert, we understand thoroughly what our customers need. So, we make your software with maximum excellence, creativity, and innovation.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>Modern Technologies</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">Our reliable software solutions will definitely add value to your business and your customers will experience the most user-friendly, secured, and up-to-date privacy-enabled applications.</p>
                                        </div>
                                        
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>Software Security</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">Our reliable software solutions will definitely add value to your business and your customers will experience the most user-friendly, secured, and up-to-date privacy-enabled applications.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>User Privacy</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">Our reliable software solutions will definitely add value to your business and your customers will experience the most user-friendly, secured, and up-to-date privacy-enabled applications.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>Sustainable</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">BizNextIT Bangladesh is recognized for its excellence in key technologies, and efficient project management. Believe in us and take our one-stop tech services to make your brand reliable, innovative & dynamic.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>Trustworthy</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">BizNextIT Bangladesh is recognized for its excellence in key technologies, and efficient project management. Believe in us and take our one-stop tech services to make your brand reliable, innovative & dynamic.</p>
                                        </div>
                                    </div>
                                    <div className="slideDiv slide technology">
                                        <div className="pcbText">
                                            <div className="textCenter">
                                              <p>Successful</p>
                                            </div>
                                        </div>
                                        <div className="description">
                                            <p className="lineUp">BizNextIT Bangladesh is recognized for its excellence in key technologies, and efficient project management. Believe in us and take our one-stop tech services to make your brand reliable, innovative & dynamic.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                                <div className="imagesBox">
                                    <img className="centerImage" src={centerImage} alt="BizNextIT"/>

                                    <div className="mouseMovement">
                                        <div className="imageAnimateNew firstPart upsideDown">
                                            <img src={img_calendar} loading="lazy" width="69" alt="img_calendar" className="up"/>
                                        </div>

                                        <div className="imageAnimateNew secondPart">
                                            <img src={img_code} loading="lazy" width="69" alt="img_code" className="up"/>
                                        </div>
                                        <div className="imageAnimateNew fourthPart ">
                                            <img src={img_cloud2} loading="lazy" width="69" alt="img_cloud2" className="up"/>
                                        </div>

                                        <div className="imageAnimateNew upsideDown fifthPart ">
                                            <img src={img_cloud1} loading="lazy" width="69" alt="img_cloud1" className="up"/>
                                        </div>

                                        <div className="imageAnimateNew wavePart ">
                                            <img src={img_chart} loading="lazy" width="69" alt="img_chart" className="up"/>
                                        </div>

                                        <div className="imageAnimateNew sixthPart ">
                                            <img src={img_loud} loading="lazy" width="69" alt="img_loud" className="up"/>
                                        </div>
                                        <div className="imageAnimateNew upsideDown thirdPart ">
                                            <img src={img_tree} loading="lazy" width="69" alt="img_tree" className="up"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="slidingElements">
                                <div className="slidingImage">
                                    <img src={img_men} alt="BizNextIT"/>
                                </div>
                                <div className="slidingImage second">
                                    <img src={img_woman} alt="BizNextIT"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
    );
  }
}
export default TopBanner;